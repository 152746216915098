<template>
	<div class="image-container" :loaded="loaded" :gray="gray" :loader="!loaded && !placeholder">
		<div class="image placeholder" :style="{backgroundImage: placeholder ? `url(${placeholder})` : null}"></div>
		<div class="image" :style="{backgroundImage: `url(${src})`, backgroundSize: size, backgroundPosition: position, backgroundRepeat: repeat}"></div>
		<img ref="image" :src="src" @load="onLoad">
	</div>
</template>

<script>
export default {
	name: "BpsImage",
	props: {
		src: {
			type: String
		},
		gray: {
			type: Boolean,
		},
		size: {
			type: String,
			default: 'cover'
		},
		position: {
			type: String,
			default: 'center'
		},
		repeat: {
			type: String,
			default: 'no-repeat'
		},
		placeholder: {
			type: String
		},
		delay: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			loaded: false
		}
	},
	watch: {
		src() {
			clearTimeout(this.imgTO)
			this.loaded = false
			this.$nextTick(() => {
				if(this.$refs.image.complete) this.loaded = true
			})
		}
	},
	methods: {
		onLoad() {
			clearTimeout(this.imgTO)
			this.imgTO = setTimeout(() => this.loaded = true, this.delay)
		}
	}
};
</script>

<style scoped>
.image-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.image-container[loader]:before {
	display: none;
}

.image-container .image {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
}

.image-container[gray] {
	filter: grayscale(1);
}

.image-container .image.placeholder {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	opacity: 1;
	filter: blur(15px);
}

.image-container[loaded] .image {
	transition: opacity .16s ease-in;
	opacity: 1;
}

.image-container[loaded] .image.placeholder {
	opacity: 0;
}

img {
	position: absolute;
	width: 1px;
	height: 1px;
	opacity: 0;
	top: -1px;
	left: -1px;
}
</style>
