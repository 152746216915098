<template>
    <div class="view category" v-if="category">
		<div class="view-title">
			<div class="collection">
				<router-link to="/" v-if="isMobile" class="back">
					<button class="btn"><i class="ic ic-chevron-left"></i></button>
					<img src="@/assets/images/bps_logo_store.svg" alt="Merch Store">
				</router-link>
				<span>2019 - 2020<br>Black Pug Studio Collection</span>
			</div>
			<h1>{{category.name}}</h1>
			<h3 v-if="isMobile">{{category.name}}</h3>
		</div>
		<div class="products-container">
			<div :style="isMobile ? null : {transform: `translateY(${getPosition(pageIndex, selectedPage)}%)`}" class="products" v-for="(i, pageIndex) in Math.ceil(productsWithVariants.length / productPerPage)" :key="`products-page-${i}`">
				<router-link class="product" v-for="product in productsWithVariants.slice(pageIndex * productPerPage, (pageIndex * productPerPage) + productPerPage)" :to="`/${product.slug}`" :key="product.id" :limited="product.limited">
					<div class="product-image" :dynamic="`product-image-${product.id}`">
						<BpsImage :gray="product.unavailable" :placeholder="product.variants[product.default_variant ? product.variants.findIndex(v => v.id == product.default_variant) : 0].images[0].placeholder" :src="product.variants[product.default_variant ? product.variants.findIndex(v => v.id == product.default_variant) : 0].images[0].url" />
					</div>
					<div class="product-info">
						<div class="product-name">{{product.name}}</div>
						<!-- <div class="product-price">€{{product.variants[0].retail_price}}</div> -->
						<div class="product-price">€{{[].concat(product.variants).sort((a, b) => parseFloat(a.retail_price) - parseFloat(b.retail_price))[0].retail_price}}</div>
					</div>
					<div class="product-unavailable" v-if="product.unavailable">
						Unavailable
					</div>
					<div v-else class="product-variants">{{product.variants.reduce((colors, v) => {
						if(!colors.includes(v.details.color)) colors.push(v.details.color)
						return colors
					}, []).length}} color variations</div>
					<div class="product-limited" v-if="product.limited">Limited Edition</div>
					<!-- <div class="limited-tag" v-if="product.limited"></div> -->
				</router-link>
			</div>
		</div>
		<div class="page-selector" v-if="!isMobile && productsWithVariants.length > productPerPage">
			<button v-for="(i, pageIndex) in Math.ceil(productsWithVariants.length / productPerPage)" :key="`page-selector-${i}`" type="button" class="selector" @click="selectedPage = pageIndex" :selected="selectedPage === pageIndex"></button>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import BpsImage from '../components/BpsImage'

export default {
	name: "category",
	components: {
		BpsImage
	},
	computed: {
		...mapGetters({
			categories: 'getCategories',
			products: 'getProducts',
			variants: 'getVariants',
			cart: 'getCart',
			isMobile: 'getMobile'
		}),
		category() {
			return this.categories.find(c => c.slug === this.$route.params.id) || null
		},
		productsWithVariants() {
			return this.products.filter(p => p.category === this.category.slug).reduce((products, p) => {
				p.variants = this.variants.filter(v => v.sync_product_id === p.id)
				if(p.variants.length) products.push(p)
				return products
			}, [])
		}
	},
	data() {
		return {
			selectedPage: 0,
			productPerPage: 3
		}
	},
	watch: {
		productsWithVariants() {
			this.viewProducts()
		}
	},
	methods: {
		formatPrice(p) {
			if (isNaN(p)) return "0.00"
			return parseFloat(p).toFixed(2)
		},
		getPosition(index, page) {
			if(index > page) return 100
			else if(index < page) return -100
			return 0
		},
		viewProducts() {
			if(typeof window.ga === 'function' && this.productsWithVariants.length) {
				this.productsWithVariants.map((product, i) => {
					window.ga('ec:addImpression', {
						id: product.id,
						name: product.name,
						category: product.category,
						variant: product.variants[0].name,
						position: i + 1
					})
				})
				window.ga('set', 'page', this.$route.path)
				window.ga('send', 'pageview')
			}
			if(this.productsWithVariants && this.productsWithVariants.length) {
				window.dispatchEvent(new CustomEvent('setLoading', {detail: {value: false}}))
			}
		},
		scrollEvent(e) {
			this.selectedPage = Math.min(Math.max(0, this.selectedPage + (e.deltaY < 0 ? -1 : 1)), Math.ceil(this.productsWithVariants.length / this.productPerPage) - 1)
		}
	},
	mounted: function() {
		this.viewProducts()
		if(!this.isMobile) {
			['DOMMouseScroll', 'mousewheel', 'wheel'].map(event => {
				window.addEventListener(event, this.scrollEvent)
			})
		}
	},
	beforeDestroy: function() {
		if(!this.isMobile) {
			['DOMMouseScroll', 'mousewheel', 'wheel'].map(event => {
				window.removeEventListener(event, this.scrollEvent)
			})
		}
	}
}
</script>

<style scoped>
.view {
	position: absolute;
	display: flex;
	flex-flow: column nowrap;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.products-container {
	flex-grow: 1;
	overflow: hidden;
	position: relative;
}

.page-selector {
	position: absolute;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	top: 0;
	bottom: 10vh;
	right: 2.08vw;
	width: 8px;
	z-index: 3;
}

.page-selector .selector {
	display: block;
	width: 100%;
	height: 6.48vh;
	margin: 5px 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: none;
}

.page-selector .selector:after {
	content: '';
	display: block;
	width: 2px;
	height: 100%;
	margin: auto;
	background: #fff;
	opacity: .23;
	transition: opacity .16s ease-out;
}

.page-selector .selector[selected]:after {
	opacity: 1;
}

.products {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	z-index: 2;
	margin-top: 5vh;
	transition: transform .16s ease-out;
}

.product {
	position: relative;
	width: 23.75vw;
	margin: 0 1.66vw;
}

.product .product-name,
.product .product-price,
.product .product-variants,
.product .product-unavailable,
.product .product-limited {
	margin-top: .5vh;
}

.product .product-name {
	font-size: 1.85vh;
	font-weight: 600;
}

.product .product-price {
	font-size: 2.04vh;
	font-weight: 900;
}

.product .product-variants,
.product .product-unavailable {
	color: #208E7F;
	text-transform: uppercase;
	font-family: 'Inter';
	font-weight: 500;
}

.product .product-unavailable {
	color: #ea2c3c;
}

.product .product-limited {
	color: #EDBA52;
	font-size: 1.38vh;
	font-family: 'Inter';
	font-weight: bold;
}

.product .product-image {
	position: relative;
	height: 52.4vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.product[limited] .product-image {
	border: 5px solid transparent;
}

.product[limited] .product-image:after {
	content: '';
	position: absolute;
	top: -5px;
	left: -5px;
	height: calc(100% + 10px);
	width: calc(100% + 10px);
	background: linear-gradient(60deg, #EDBA52, #EDBA52, #EDBA52, #fff, #EDBA52, #EDBA52, #EDBA52);
	z-index: -1;
	animation: animatedgradient 4s ease alternate infinite;
	background-size: 1000% 1000%;
}

.product .limited-tag {
	position: absolute;
	top: -3.125vw;
	right: -2.7vw;
	width: 5.1vw;
	height: 5.1vw;
	background: url(../assets/images/bps_i_limited.svg) no-repeat center/contain;
}

@media screen and (max-width: 1023px) {
	.view {
		position: relative;
		display: block;
		width: auto;
		height: auto;
		padding-top: 102px;
	}
	.products {
		position: relative;
		display: block;
		width: auto;
		height: auto;
		margin: 0;
		padding: 0 32px;
	}
	.product {
		display: block;
		width: auto;
		margin: 0 0 40px;
	}
	.product .product-image {
		position: relative;
		height: auto;
		padding-top: 100vw;
		margin: 0 -32px 16px;
	}
	.product .product-image .image-container {
		position: absolute;
		top: 0;
		left: 0;
	}
	.product .product-info {
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
	}
	.product .product-name {
		font-size: 16px;
		font-weight: bold;
		margin: 0;
	}
	.product .product-price {
		font-size: 16px;
		font-weight: 900;
		margin: 0;
	}
	.product .product-variants,
	.product .product-unavailable {
		font-size: 12px;
		margin-top: 6px;
	}
	.product .product-limited {
		margin-top: 2px;
		font-weight: 600;
		font-size: 12px;
	}
	.view .view-title .collection .btn {
		width: 8px;
		height: 24px;
	}
}

@media screen and (max-width: 410px) {
	.products {
		padding: 0 24px;
	}
	.categories .category h2 {
		font-size: 32px;
	}
}
</style>
